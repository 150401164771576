<template>
  <div class="addProject">
    <ds-header :title="`项目管理 / ${headerTitle}`"></ds-header>
    <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="mainLabelCol" :wrapper-col="mainWrapperCol">

      <div  class="module-box">
        <div class="module-title">
            基础信息
        </div>
        <div class="module-content">
          <a-row type="flex" justify="space-around" align="middle">
            <a-col :span="12">
              <a-form-model-item label="项目级别：" prop="child_type" :labelCol="itemLabelCol" :wrapperCol="itemWrapperCol">
                <a-radio-group :disabled="disableParent || isDisable" @change="handleTypeChange" v-model="form.child_type">
                  <a-radio :value="0">
                    主项目
                  </a-radio>
                  <a-radio :value="1">
                    子项目
                  </a-radio>
                </a-radio-group>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="总项目：" v-if="form.child_type === 1" prop="parent_id" :required="form.child_type === 1" :labelCol="itemLabelCol" :wrapperCol="itemWrapperCol">
                <a-select
                  v-model="form.parent_id"
                  placeholder="请选择总项目"
                  :disabled="disableParent || isDisable"
                >
                  <a-select-option v-for="item in parentProjects" :key="item.id">
                    {{ item.project_name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row type="flex" justify="space-around" align="middle">
            <a-col :span="12">
              <a-form-model-item label="项目名称：" prop="project_name" :labelCol="itemLabelCol" :wrapperCol="itemWrapperCol">
                <a-input placeholder="请输入项目名称" v-model.trim="form.project_name" :disabled="isDisable" />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="所在地区：" required class="setMarginBottom" :labelCol="itemLabelCol"
                                 :wrapperCol="itemWrapperCol">
                <a-row>
                  <a-col :span="8">
                    <a-form-model-item prop="region1">
                      <a-select v-model="form.region1" placeholder="请选择"
                                :getPopupContainer="(triggerNode) => triggerNode.parentNode" @change="selectedProvince"
                                :disabled="isDisable">
                        <a-select-option v-for="(item, index) in option1" :key="index" :value="item.code">
                          {{ item.name }}
                        </a-select-option>
                      </a-select>
                    </a-form-model-item>
                  </a-col>
                  <!-- <a-col :span="1"></a-col> -->
                  <a-col :span="8">
                    <a-form-model-item prop="region2">
                      <a-select v-model="form.region2" placeholder="请选择"
                                :getPopupContainer="(triggerNode) => triggerNode.parentNode" @change="selectedCity"
                                :disabled="isDisable">
                        <a-select-option v-for="(item, index) in option2" :key="index" :value="item.code">
                          {{ item.name }}
                        </a-select-option>
                      </a-select>
                    </a-form-model-item>
                  </a-col>
                  <!-- <a-col :span="1"></a-col> -->
                  <a-col :span="8">
                    <a-form-model-item prop="region3">
                      <a-select v-model="form.region3" placeholder="请选择"
                                :getPopupContainer="(triggerNode) => triggerNode.parentNode" @change="selectedDistrict"
                                :disabled="isDisable">
                        <a-select-option v-for="(item, index) in option3" :key="index" :value="item.code">
                          {{ item.name }}
                        </a-select-option>
                      </a-select>
                    </a-form-model-item>
                  </a-col>
                </a-row>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row type="flex" justify="space-around">
            <a-col :span="12">
              <a-form-model-item label="详细地址：" prop="address" :labelCol="itemLabelCol" :wrapperCol="itemWrapperCol">
                <a-input placeholder="请输入详细地址" :value="form.address" @input="(e) => (form.address = forbidEmoji(e))"
                         :disabled="isDisable" />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="地址坐标：" :labelCol="itemLabelCol" :wrapperCol="itemWrapperCol" :required="form.child_type === 1">
                <a-row v-for="(item, index) in form.coordinates" :key="index">
                  <a-col :span="9">
                    <a-form-model-item>
                      <a-input placeholder="请输入地址经度" :value="item.abscissa" @input="(e) => (item.abscissa = pointVerify(e))"
                               :disabled="isDisable" />
                    </a-form-model-item>
                  </a-col>
                  <a-col :span="1"></a-col>
                  <a-col :span="9">
                    <a-form-model-item>
                      <a-input placeholder="请输入地址纬度" :value="item.ordinate" @input="(e) => (item.ordinate = pointVerify(e))"
                               :disabled="isDisable" />
                    </a-form-model-item>
                  </a-col>
                  <a-col :span="1"></a-col>
                  <a-col :span="1">
                    <a-button type="primary" v-if="isDisable ? false : index === form.coordinates.length - 1"
                              @click="addProjectCoordinate" :disabled="form.coordinates.length >= 9">
                      <a-icon type="plus" />
                    </a-button>
                  </a-col>
                  <a-col :span="2"></a-col>
                  <a-col :span="1">
                    <a-button v-if="isDisable ? false : form.coordinates.length > 3" @click="deleteProjectCoordinate(item)">
                      <a-icon type="minus" />
                    </a-button>
                  </a-col>
                </a-row>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row type="flex" justify="space-around" align="middle">
            <a-col :span="12">
              <a-form-model-item label="客服热线电话：" prop="customer_service_tel" :labelCol="itemLabelCol" :wrapperCol="itemWrapperCol">
                <a-input placeholder="请输入客服热线电话" v-model.trim="form.customer_service_tel" :disabled="isDisable" />
                <div class="metro-card-hint" style="color: #000;">
                  温馨提示：如项目有客服热线电话请填写，不填写则无客服热线电话，APP/小程序不显示
                </div>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="物业电话：" prop="telephone" :labelCol="itemLabelCol" :wrapperCol="itemWrapperCol">
                <a-input placeholder="请输入物业电话" v-model="form.telephone" :disabled="isDisable" />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row type="flex" justify="space-around" align="middle">
            <a-col :span="12">
              <a-form-model-item label="其他联系电话：" prop="project_tel" :labelCol="itemLabelCol" :wrapperCol="itemWrapperCol">
                <a-row v-for="(item, index) in form.project_tel" :key="index">
                  <a-col :span="9">
                    <a-form-model-item>
                      <a-input placeholder="请输入电话名称" v-model="item.name" :disabled="isDisable" :maxLength="10" />
                    </a-form-model-item>
                  </a-col>
                  <a-col :span="1"></a-col>
                  <a-col :span="9">
                    <a-form-model-item>
                      <a-input placeholder="请输入电话号码" v-model="item.mobile" :disabled="isDisable" />
                    </a-form-model-item>
                  </a-col>
                  <a-col :span="1"></a-col>
                  <a-col :span="1">
                    <a-button type="primary" v-if="!isDisable"
                              @click="addOtherContactNumber">
                      <a-icon type="plus" />
                    </a-button>
                  </a-col>
                  <a-col :span="2"></a-col>
                  <a-col :span="1">
                    <a-button v-if="isDisable ? false : form.project_tel.length > 1" @click="deleteOtherContactNumber(item)">
                      <a-icon type="minus" />
                    </a-button>
                  </a-col>
                </a-row>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="项目类型：" prop="project_type" :labelCol="itemLabelCol" :wrapperCol="itemWrapperCol">
                <a-radio-group name="radioGroup" v-model="form.project_type" :disabled="isDisable">
                  <a-radio :value="0">园区</a-radio>
                  <a-radio :value="1">小区</a-radio>
                  <a-radio :value="2">土地</a-radio>
                </a-radio-group>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row type="flex" justify="space-around">
            <a-col :span="12">
              <a-form-model-item label="营业时间：" :labelCol="itemLabelCol" :wrapperCol="itemWrapperCol" required>
                <a-row>
                  <a-col :span="11">
                    <a-form-model-item prop="start">
                      <a-time-picker format="HH:mm" placeholder="开始时间" @change="selectStartTime" v-model="form.start"
                                     :getPopupContainer="(triggerNode) => triggerNode.parentNode" inputReadOnly :disabled="isDisable"
                                     :disabledHours="getStartDisabledHours" :disabledMinutes="getStartDisabledMinutes" />
                    </a-form-model-item>
                  </a-col>
                  <a-col :span="2"></a-col>
                  <a-col :span="11">
                    <a-form-model-item prop="end">
                      <a-time-picker format="HH:mm" placeholder="结束时间" @change="selectEndTime" v-model="form.end"
                                     :getPopupContainer="(triggerNode) => triggerNode.parentNode" inputReadOnly :disabled="isDisable"
                                     :disabledHours="getEndDisabledHours" :disabledMinutes="getEndDisabledMinutes" />
                    </a-form-model-item>
                  </a-col>
                </a-row>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="项目介绍：" prop="project_intr" :labelCol="itemLabelCol" :wrapperCol="itemWrapperCol">
                <a-textarea placeholder="请输入项目简介" v-model="form.project_intr" :rows="6" :disabled="isDisable" />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row type="flex" justify="space-around">
            <a-col :span="12">
              <a-form-model-item label="项目图片：" :labelCol="itemLabelCol" :wrapperCol="itemWrapperCol">
                <a-upload list-type="picture-card" class="avatar-uploader" :action="IMG_API + '/oss/files'"
                          :before-upload="beforeUpload" @change="uploadEnterLogo" :disabled="isDisable ? true : isHideLogo"
                          :show-upload-list="false" promptTitle="请上传图片尺寸670 x 300，小于2M的图片 JPG、PNG" :fileList="uploadlist">
                  <img v-if="form.project_image" :src="form.project_image" class="showPicture" />
                  <div v-else>
                    <a-icon :type="loading ? 'loading' : 'plus'" />
                    <div class="ant-upload-text">上传项目图片</div>
                  </div>
                </a-upload>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="排序" prop="sort">
                <a-input-number :disabled="isDisable" v-model="form.sort" :precision="0" :min="1" :max="9999"/>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row type="flex" justify="space-around">
            <a-col :span="12">
              <a-form-model-item label="是否支持一卡通：" prop="support_ykt" :labelCol="itemLabelCol" :wrapperCol="itemWrapperCol">
                <a-radio-group v-model="form.support_ykt" :disabled="isDisable">
                  <a-radio :value="1">
                    是
                  </a-radio>
                  <a-radio :value="0">
                    否
                  </a-radio>
                </a-radio-group>
                <div class="metro-card-hint">
                  判断项目页面是否透出一卡通功能(付款码、充值、钱包), 前端展示形式将做对应变化
                </div>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="开工时间">
                <a-date-picker v-model="form.start_time" :disabled="isDisable" show-time placeholder="请选择开工时间"/>
              </a-form-model-item>
              <a-form-model-item label="完工时间">
                <a-date-picker v-model="form.finish_time" :disabled="isDisable" show-time placeholder="请选择完工时间"/>
              </a-form-model-item>
              <a-form-model-item label="竣工时间">
                <a-date-picker v-model="form.completion_time" :disabled="isDisable" show-time placeholder="请选择竣工时间"/>
              </a-form-model-item>
              <a-form-model-item label="闭园时间">
                <a-date-picker v-model="form.closure_time" :disabled="isDisable" show-time placeholder="请选择闭园时间"/>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row type="flex">
            <a-col :span="12">
              <a-form-model-item label="透出端口：" :labelCol="itemLabelCol" :wrapperCol="itemWrapperCol" v-if="isDisable||form.child_type===1||!['08de21ae53800000','08949e79c7000000'].includes(tenantId)">
                <a-checkbox-group v-model="form.port" :options="penetratePortList" @change="selectPort" :disabled="isDisable" />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="入园登记码：" v-if="isDisable">
                <div @click="saveImg()" id="qrcode" ref="qrCodeUrl" style="width: 200px;height: 200px;"></div>
              </a-form-model-item>
            </a-col>
          </a-row>
        </div>
      </div>
      <div class="module-box margin-module">
        <div class="module-title">
          土地使用情况
        </div>
        <div class="module-content">
          <a-row>
            <a-col :span="12">
              <a-form-model-item label="土地证号：">
                <a-input placeholder="请输入土地证号" :maxLength="100" v-model.trim="form.project_land.lc_no" :disabled="isDisable" />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="证载使用方：">
                <a-input placeholder="请输入证载使用方" :maxLength="100" v-model.trim="form.project_land.lc_user" :disabled="isDisable" />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-model-item label="证载面积：">
                <a-input-number  :style="{ width: '95%' }" :precision="2" placeholder="请输入证载面积" :max="99999999" v-model.trim="form.project_land.lc_area" :disabled="isDisable" suffix="" /> ㎡
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="证载土地用途：">
                <a-input placeholder="请输入证载土地用途" :maxLength="100" v-model.trim="form.project_land.lc_usage" :disabled="isDisable" />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-model-item label="实际可用面积：">
                <a-input-number :style="{ width: '95%' }" :precision="2" placeholder="请输入实际可用面积" :max="99999999" v-model.trim="form.project_land.real_area" :disabled="isDisable" /> ㎡
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="实际使用人：">
                <a-input placeholder="请输入实际使用人" :maxLength="100" v-model.trim="form.project_land.real_user" :disabled="isDisable" />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-model-item label="实际土地用途：">
                <a-input placeholder="请输入实际土地用途" :maxLength="100" v-model.trim="form.project_land.real_usage" :disabled="isDisable" />
              </a-form-model-item>
            </a-col>
          </a-row>
        </div>
      </div>
      <div class="module-box margin-module" v-for="(item, index) in form.project_buildings" :key="index">
        <div class="module-title">
          <div class="module-title-content">
            <div>{{ `房屋情况${index + 1}` }}</div>
            <div><a-icon @click="removeBuild(index)" type="delete" /></div>
          </div>
        </div>
        <div class="module-content">
          <a-row>
            <a-col :span="12">
              <a-form-model-item label="房产证号：">
                <a-input placeholder="请输入房产证号" :maxLength="100" v-model.trim="item.pc_no" :disabled="isDisable" />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="房屋位置：">
                <a-input placeholder="请输入房屋位置" :maxLength="100" v-model.trim="item.pc_location" :disabled="isDisable" />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-model-item label="证载使用方：">
                <a-input placeholder="请输入证载使用方" :maxLength="100" v-model.trim="item.pc_user" :disabled="isDisable" />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="证载面积：">
                <a-input-number :style="{ width: '95%' }" :precision="2" placeholder="请输入证载面积" :max="99999999" v-model.trim="item.pc_area" :disabled="isDisable" /> ㎡
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-model-item label="实际使用人：">
                <a-input placeholder="请输入实际使用人" :maxLength="100" v-model.trim="item.real_user" :disabled="isDisable" />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="实际可用面积：">
                <a-input-number :style="{ width: '95%' }" :precision="2" placeholder="请输入实际可用面积" :max="99999999" v-model.trim="item.real_area" disabled /> ㎡
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-model-item label="实际可租面积：">
                <a-input-number :style="{ width: '95%' }" :precision="2" placeholder="请输入实际可租面积" :max="99999999" v-model.trim="item.real_rentable_area" disabled /> ㎡
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="实际不可租面积：">
                <a-input-number :style="{ width: '95%' }" :precision="2" placeholder="请输入实际不可租面积" :max="99999999" v-model.trim="item.real_non_rentable_area" disabled /> ㎡
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-model-item label="原值：">
                <a-input placeholder="请输入原值" :maxLength="10" v-model.trim="item.original_value" :disabled="isDisable" />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="净值：">
                <a-input placeholder="请输入净值" :maxLength="10" v-model.trim="item.net_value" :disabled="isDisable" />
              </a-form-model-item>
            </a-col>
          </a-row>
        </div>
      </div>
      <div :style="{ margin: '20px 0' }">
        <a-button v-show="!isDisable" type="primary" icon="plus-circle" size="large" @click="addProjectBuilding">
          再次添加
        </a-button>
      </div>
    </a-form-model>
    <div class="bottomClass">
      <ds-button :type="$DSBT.DS_SUBMIT" @click="onSubmit" v-if="!isDisable" />
      <ds-button :type="$DSBT.DS_BACK" @click="goBack" style="margin-left: 20px" />
    </div>
  </div>
</template>

<script>
import QRCode from 'qrcodejs2'
import { isvalidPhone } from "@/utils/validate";
import { IMG_API } from "@/config";
import Area from "@/utils/area";
import * as api from "@/api/baseSet/projectManage";
import { mapState } from "vuex";
import moment from "moment";
import PenetrateStatus from "../utils/penetrateUtil";
import debounce from "lodash/debounce";
import DSUpload from "@/components/DSComponents/DSUpload";
import {getParentProjectList} from "@/api/baseSet/projectManage";
import { getLocalStorage } from "@/utils/storage";

export default {
  name: "AddProject",
  components: {
    DSUpload,
  },
  computed: {
    isDisable() {
      return this.showType === "2" ? true : false;
    },
    ...mapState({
      isLoading: (state) => state.load.loading,
    }),
  },
  data() {
    let validatorParentId = (rule, value, callback) => {
      if (!value && this.form.child_type === 1) {
        callback(new Error('请选择主项目'));
      } else {
        callback();
      }
    };
    return {
      // 是否是添加子项目的操作, 用户从列表添加子项目的时候要禁用项目类型和项目选择
      disableParent: false,
      // 父级项目list
      parentProjects: [],
      IMG_API: IMG_API,
      loading: false,
      mainLabelCol: { span: 6 },
      mainWrapperCol: { span: 18 },
      itemLabelCol: { span: 6 },
      itemWrapperCol: { span: 12 },
      //显示状态 0.添加, 1.编辑, 2.查看
      showType: "",
      //header显示文字
      headerTitle: "",
      //项目id
      id: "",
      //上传图片列表
      uploadlist: [],
      //form表单
      form: {
        //项目名称
        project_name: "",
        //省
        region1Name: "",
        //市
        region2Name: "",
        //区
        region3Name: "",
        //省邮编
        region1: undefined,
        //市邮编
        region2: undefined,
        //区邮编
        region3: undefined,
        //项目地区
        project_area: "",
        //行政区域名称
        project_area_name: "",
        //项目地址
        address: "",
        //项目坐标
        coordinates: [
          {
            abscissa: "",
            ordinate: "",
            id: 0,
          },
          {
            abscissa: "",
            ordinate: "",
            id: 1,
          },
          {
            abscissa: "",
            ordinate: "",
            id: 2,
          },
        ],
        //物业电话
        telephone: "",
        //透出端口 1.APP, 2.微信小程序
        port: null,
        //营业开始时间
        start: null,
        //营业结束时间
        end: null,
        //营业时间
        business_hours: "",
        //项目介绍
        project_intr: "",
        //项目图片
        project_image: "",
        //透出端
        penetrate: "",
        // 是否支持一卡通
        support_ykt: undefined,
        // 客服热线电话
        customer_service_tel: '',
        // 项目级别 0: 主项目 1: 子项目
        child_type: undefined,
        // 父级id
        parent_id: undefined,
        // 排序
        sort: '',
        project_type:'',//项目类型
        // 开工时间
        start_time: undefined,
        // 完工时间
        finish_time: undefined,
        // 竣工时间
        completion_time: undefined,
        // 闭园时间
        closure_time: undefined,
        project_tel: [
          {
            name: '', // 电话名称,
            mobile: '', // 电话号码
            id: 0
          }
        ],
        // 项目土地情况
        project_land: {
          // 土地证号
          lc_no: '',
          // 证载使用方
          lc_user: '',
          // 证载面积
          lc_area: '',
          // 证载土地用途
          lc_usage: '',
          // 实际可用面积
          real_area: '',
          // 实际使用人
          real_user: '',
          // 实际土地用途
          real_usage: ''
        },
        // 房屋情况
        project_buildings: [
          {
            // 房产证号
            pc_no: '',
            // 房屋位置
            pc_location: '',
            // 证载使用方
            pc_user: '',
            // 证载面积
            pc_area: '',
            // 实际使用人
            real_user: '',
            // 实际可用面积
            real_area: '',
            // 实际可租面积
            real_rentable_area: '',
            // 实际不可租面积
            real_non_rentable_area: '',
            // 原值
            original_value: '',
            // 净值
            net_value: ''
          }
        ]
      },
      //是否隐藏企业logo上传按钮
      isHideLogo: false,
      //透出端口数据
      penetratePortList: ["APP", "微信小程序"],
      //表单验证
      rules: {
        child_type: [
          { required: true, message: '请选择项目类型', trigger: 'blur' }
        ],
        project_name: [
          { required: true, message: "请输入项目名称", trigger: ["blur"] },
          { max: 20, message: "项目名称最多20个字", trigger: ["change", "blur"] },
        ],
        parent_id: [
          { validator: validatorParentId, trigger: 'change' }
        ],
        region1: [{ required: true, message: "请选择省份", trigger: ["blur"] }],
        region2: [{ required: true, message: "请选择城市", trigger: ["blur"] }],
        region3: [{ required: true, message: "请选择区", trigger: ["blur"] }],
        address: [
          { required: true, message: "请输入详细地址", trigger: ["blur"] },
          { max: 50, message: "详细地址最多50个字", trigger: ["change", "blur"] },
        ],
        telephone: [{ validator: isvalidPhone, trigger: ["blur"] }],
        // port: [{ required: true, message: "请选择透出端口", trigger: ["change"] }],
        project_intr: [{ max: 500, message: "项目介绍最多500个字", trigger: ["change", "blur"] }],
        start: [{ required: true, message: "请设置开始时间", trigger: ["change"] }],
        end: [{ required: true, message: "请设置结束时间", trigger: ["change"] }],
        support_ykt: [{ required: true, message: "请选择是否支持一卡通", trigger: ["change"] }],
        sort: [{ required: true, message: '请输入排序', trigger: 'blur' }],
        projectType: [{ required: true, message: "请选择项目类型", trigger: ["change"] }],
      },

      //临时数据
      option1: [],
      option2: {},
      option3: {},
      tenantId:getLocalStorage("user").tenantId
    };
  },
  methods: {
    /**
     * 根据下标删除房屋使用情况
     */
    removeBuild(index) {
      this.form.project_buildings.splice(index, 1)
    },
    /**
     * 添加房屋使用情况
     */
    addProjectBuilding() {
      this.form.project_buildings.push({
        // 房产证号
        pc_no: '',
        // 房屋位置
        pc_location: '',
        // 证载使用方
        pc_user: '',
        // 证载面积
        pc_area: '',
        // 实际使用人
        real_user: '',
        // 实际可用面积
        real_area: '',
        // 实际可租面积
        real_rentable_area: '',
        // 实际不可租面积
        real_non_rentable_area: ''
      })
    },
    /**
     * 项目级别change事件
     */
    handleTypeChange(e) {
      const val = e.target.value;
      if (val === 0) {
        this.form.parent_id = ''
      }
    },
    // 查询父级项目
    async getParentProjects(id) {
      const res = await getParentProjectList(id);
      const { code, data, msg } = res
      if(code === '200') {
        this.parentProjects = data
      } else {
        this.$message.error(msg)
      }
    },
    //返回
    goBack() {
      this.$router.go(-1);
    },
    //选择省
    selectedProvince(code) {
      this.form.region2Name = undefined;
      this.form.region3Name = undefined;
      this.form.region2 = undefined;
      this.form.region3 = undefined;
      this.form.project_area_name = "";
      this.form.project_area = "";
      const data = this.option1.filter((item) => {
        if (item.code === code) {
          return item;
        }
      })[0];
      this.form.region1Name = data.name;
      this.form.region1 = code;
      this.getCityList(code);
    },
    //选择市
    selectedCity(code) {
      this.form.region3Name = undefined;
      this.form.region3 = undefined;
      this.form.project_area_name = "";
      this.form.project_area = "";
      const data = this.option2.filter((item) => {
        if (item.code === code) {
          return item;
        }
      })[0];
      this.form.region2Name = data.name;
      this.form.region2 = code;
      this.getDistrictList(code);
    },
    //选择区
    selectedDistrict(code) {
      const data = this.option3.filter((item) => {
        if (item.code === code) {
          return item;
        }
      })[0];
      this.form.region3Name = data.name;
      this.form.region3 = code;
      this.form.project_area = code;
      this.form.project_area_name = `${this.form.region1}-${this.form.region2}-${this.form.region3}/${this.form.region1Name}-${this.form.region2Name}-${this.form.region3Name}`;
    },
    //添加坐标
    addProjectCoordinate() {
      this.form.coordinates.push({
        abscissa: "",
        ordinate: "",
        id: this.form.coordinates.length,
      });
    },
    //删除坐标
    deleteProjectCoordinate(data) {
      let arr = [];
      var index = 0;
      this.form.coordinates.forEach((item) => {
        if (item.id !== data.id) {
          arr.push({
            abscissa: item.abscissa,
            ordinate: item.ordinate,
            id: index++,
          });
        }
      });
      this.form.coordinates = arr;
    },
    //选择透出端
    selectPort(data) {
      if (data.indexOf("APP") !== -1 && data.indexOf("微信小程序") !== -1) {
        this.form.penetrate = PenetrateStatus.PENETRATE_ALL;
      } else if (data.indexOf("APP") !== -1) {
        this.form.penetrate = PenetrateStatus.PENETRATE_APP;
      } else if (data.indexOf("微信小程序") !== -1) {
        this.form.penetrate = PenetrateStatus.PENETRATE_APPLET;
      } else {
        this.form.penetrate = "";
      }
    },
    //选择开始时间
    selectStartTime() {
      if (this.form.end) {
        this.form.business_hours = `${this.form.start},${this.form.end}`;
      }
    },
    //选择结束时间
    selectEndTime() {
      if (this.form.start) {
        this.form.business_hours = `${this.form.start},${this.form.end}`;
      }
    },
    //上传图片前校验
    beforeUpload(file) {
      const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/jpg";
      if (!isJpgOrPng) {
        this.$message.error("请上传jpg或png格式");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("图片最大2M");
      }
      return isJpgOrPng && isLt2M;
    },
    //上传项目图片
    uploadEnterLogo(data) {
      if (data.file.status === "uploading") {
        this.isHideLogo = true;
      } else {
        this.isHideLogo = false;
      }
      if (data.fileList.length === 0) {
        this.form.project_image = "";
        return;
      }
      if (data.file.response) {
        this.form.project_image = data.file.response.redirect_uri;
      }
      this.uploadlist = data.fileList;
    },
    // 添加其他联系电话
    addOtherContactNumber() {
      this.form.project_tel.push({
        name: "",
        mobile: "",
        id: this.form.project_tel.length,
      });
    },
    // 删除其他联系电话
    deleteOtherContactNumber(data) {
      let arr = [];
      var index = 0;
      this.form.project_tel.forEach((item) => {
        if (item.id !== data.id) {
          arr.push({
            name: item.name,
            mobile: item.mobile,
            id: index++,
          });
        }
      });
      this.form.project_tel = arr;
    },
    //提交
    onSubmit: debounce(function () {
      //TODO: 提交请求
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          //判断坐标位数是否正确
          let isPointLegal = true;
          for (let i = 0; i < this.form.coordinates.length; i++) {
            const item = this.form.coordinates[i];
            if (
              item.ordinate.length > 20 ||
              item.abscissa.length > 20 ||
              item.ordinate.length < 1 ||
              item.abscissa.length < 1
            ) {
              isPointLegal = false;
              break;
            }
          }

          if (!isPointLegal && this.form.child_type === 1) {
            this.$message.error("请输入合法的坐标点");
            return;
          }
          let isContactNumberLegal = true;
          const reg = /^((0\d{2,3}-?\d{7,8})|(1[3465789]\d{9}))$/;
          for (let index = 0; index < this.form.project_tel.length; index++) {
            const item = this.form.project_tel[index];
            if (!item.name && item.mobile) {
              isContactNumberLegal = false;
              this.$message.error(`请输入${item.mobile}的电话名称`);
              break;
            }
            if (item.name) {
              if (!item.mobile ) {
                isContactNumberLegal = false;
                this.$message.error(`请输入${item.name}的电话号码`);
                break;
              }
              if (!reg.test(item.mobile)) {
                isContactNumberLegal = false;
                this.$message.error(`${item.mobile}号码输入不正确`);
                break;
              }
            }
          }
          if (!isContactNumberLegal) {
            return;
          }

          if (this.showType !== "0") {
            this.editProject(this.submitDataTidy());
          } else {
            this.addProject(this.submitDataTidy());
          }
        } else {
          this.$message.error("请完善项目资料");
        }
      });
    }, 500),
    //获取开始禁用小时
    getStartDisabledHours() {
      let hours = [];
      if (this.form.end) {
        let time = moment(this.form.end).format("HH:mm");
        let timeArr = time.split(":");
        for (let i = 24; i > parseInt(timeArr[0]); i--) {
          hours.push(i);
        }
        return hours;
      }
      return hours;
    },
    //获取开始禁用分钟
    getStartDisabledMinutes(selectedHour) {
      let minutes = [];
      let time = moment(this.form.end).format("HH:mm");
      let timeArr = time.split(":");
      if (selectedHour === parseInt(timeArr[0])) {
        for (let i = 59; i > parseInt(timeArr[1]); i--) {
          minutes.push(i);
        }
      }
      return minutes;
    },
    //获取结束禁用小时
    getEndDisabledHours() {
      let hours = [];
      if (this.form.start) {
        let time = moment(this.form.start).format("HH:mm");
        let timeArr = time.split(":");
        for (let i = 0; i < parseInt(timeArr[0]); i++) {
          hours.push(i);
        }
        return hours;
      }
      return hours;
    },
    //获取结束禁用分钟
    getEndDisabledMinutes(selectedHour) {
      let minutes = [];
      let time = moment(this.form.start).format("HH:mm");
      let timeArr = time.split(":");
      // let selectedHour = moment(selectedHour).format("HH:mm").split(":")[0];
      if (selectedHour === parseInt(timeArr[0])) {
        for (let i = 0; i < parseInt(timeArr[1]); i++) {
          minutes.push(i);
        }
      }
      return minutes;
    },

    /**网络请求 */
    //添加项目
    async addProject(form) {
      try {
        const result = await api.addProject(form);
        const { code, msg } = result
        if (code === '200') {
          this.$message.success("添加成功");
          this.$router.go(-1);
        } else {
          this.$message.error(msg);
        }
      } catch (error) {
        this.$message.error("添加失败");
      }
    },
    //获取项目详情
    async getProjectDetail() {
      try {
        const result = await api.getProjectDetail(this.id);
        if (result) {
          this.dataTidy(result);
          if (result.registration_qr_code && this.isDisable) {
            this.creatQrCode(result.registration_qr_code);
          }
        }
      } catch (error) {
        this.$message.error("获取项目信息失败");
      }
    },
    // 生成二维码
    creatQrCode(code) {
      document.getElementById("qrcode").innerHTML = "";
      var qrcode = new QRCode(this.$refs.qrCodeUrl, {
        text: code, // 需要转换为二维码的内容
        width: 200,
        height: 200,
        colorDark: '#000',
        colorLight: '#fff',
        correctLevel: QRCode.CorrectLevel.H
      })
    },
    // 下载图片
    saveImg() {
      var canvasData = document.getElementById('qrcode').getElementsByTagName('img')//生成二维码之后其实是个img 所以获取完id之后还要获取到这个img 才能下载成功
      var a = document.createElement("a");
      a.href = canvasData[0].src;
      a.download = "projectCode.png";
      a.click();
    },
    //编辑项目
    async editProject(form) {
      try {
        const result = await api.editProject(form, this.id);
        const { code, msg } = result
        if (code === '200') {
          this.$message.success("编辑成功");
          this.$router.go(-1);
        } else {
          this.$message.error(msg);
        }
      } catch (error) {
        this.$message.error("编辑失败");
      }
    },

    //判断header显示文字
    judgeHeaderTitle() {
      if (this.showType === "0") {
        this.headerTitle = "新增项目";
      } else if (this.showType === "1") {
        this.headerTitle = "修改项目";
      } else {
        this.headerTitle = "查看项目";
      }
    },
    //获取省数据
    getProvinceList() {
      this.option1 = Area.getProvinceList();
    },
    //获取市数据
    getCityList(code) {
      this.option2 = Area.getCityOrDistrictList(code);
    },
    //获取区数据
    getDistrictList(code) {
      this.option3 = Area.getCityOrDistrictList(code);
    },

    /**数据处理 */
    //获取项目详情数据处理
    dataTidy(data) {
      //邮编和省市区
      let regionArr = null;
      let regionNameArr = null;
      if (data.project_area_name) {
        regionArr = data.project_area_name.split("/")[0].split("-");
        regionNameArr = data.project_area_name.split("/")[1].split("-");
      }

      //定位坐标点
      let coordinates = [];
      var index = 0;
      if (data.coordinates && data.coordinates.length) {
        data.coordinates.forEach((item) => {
          coordinates.push({
            abscissa: item.abscissa,
            ordinate: item.ordinate,
            id: index++,
          });
        });
      } else {
        for (let i = 0; i < 3; i++) {
          coordinates.push({
            abscissa: "",
            ordinate: "",
            id: index++,
          });
        }
      }
      let project_tel = [];
      let projectIndex = 0;
      if (data.project_tel && data.project_tel.length) {
        data.project_tel.forEach((item) => {
          project_tel.push({
            name: item.name,
            mobile: item.mobile,
            id: projectIndex++,
          });
        });
      } else {
        project_tel.push({
          name: '',
          mobile: '',
          id: 0
        })
      }

      //透出端
      let port = [];
      if (data.penetrate === PenetrateStatus.PENETRATE_ALL) {
        port = ["APP", "微信小程序"];
      } else if (data.penetrate === PenetrateStatus.PENETRATE_APP) {
        port = ["APP"];
      } else if (data.penetrate === PenetrateStatus.PENETRATE_APPLET) {
        port = ["微信小程序"];
      } else {
        port = [];
      }

      //营业时间
      let dateArr = null;
      if (data.business_hours && data.business_hours !== "" && data.business_hours !== "null") {
        dateArr = data.business_hours.split(",");
      } else {
        dateArr = [undefined, undefined];
      }

      this.form = {
        project_name: data.project_name,
        region1Name: regionNameArr[0],
        region2Name: regionNameArr[1],
        region3Name: regionNameArr[2],
        region1: regionArr[0],
        region2: regionArr[1],
        region3: regionArr[2],
        project_area: data.project_area,
        project_area_name: data.project_area_name,
        address: data.address,
        coordinates,
        telephone: data.telephone,
        port,
        start: dateArr[0],
        end: dateArr[1],
        business_hours:
          data.business_hours && data.business_hours !== "" && data.business_hours !== "null"
            ? data.business_hours
            : "",
        project_intr: data.project_intr,
        project_image: data.project_image,
        penetrate: data.penetrate,
        support_ykt: data.support_ykt,
        customer_service_tel: data.customer_service_tel,
        child_type: data.child_type,
        parent_id: !data.parent_id || data.parent_id === '0' ? '' : data.parent_id,
        sort: data.sort,
        project_type:data.project_type,
        project_tel,
        project_land: data.project_land || {},
        start_time: data.start_time || undefined,
        finish_time: data.finish_time || undefined,
        completion_time: data.completion_time || undefined,
        closure_time: data.closure_time || undefined,
        project_buildings: data.project_buildings && data.project_buildings.length ? data.project_buildings : [{
          // 房产证号
          pc_no: '',
          // 房屋位置
          pc_location: '',
          // 证载使用方
          pc_user: '',
          // 证载面积
          pc_area: '',
          // 实际使用人
          real_user: '',
          // 实际可用面积
          real_area: '',
          // 实际可租面积
          real_rentable_area: '',
          // 实际不可租面积
          real_non_rentable_area: ''
        }]
      };
      if (this.showType !== "0") {
        this.getCityList(this.form.region1);
        this.getDistrictList(this.form.region2);
      }
    },
    //提交数据处理
    submitDataTidy() {
      let coordinates = [];
      this.form.coordinates.forEach((item) => {
        coordinates.push({
          abscissa: item.abscissa,
          ordinate: item.ordinate,
        });
      });
      let project_tel = [];
      this.form.project_tel.forEach(item => {
        if (item.name && item.mobile) {
          project_tel.push({
            name: item.name,
            mobile: item.mobile
          })
        }
      })
      const form = {
        ...this.form,
        coordinates,
        project_tel,
        project_buildings: this.form.project_buildings,
        project_land: this.form.project_land
      };
      if (this.showType !== "0" && typeof form.project_image === "string") {
        form.project_image = [form.project_image];
      }
      if(form.child_type === 0) {
        form.parent_id = ''
      }
      if (this.form.start_time) {
        form.start_time = moment(this.form.start_time).format('YYYY-MM-DD HH:mm:ss')
      }
      if (this.form.finish_time) {
        form.finish_time = moment(this.form.finish_time).format('YYYY-MM-DD HH:mm:ss')
      }
      if (this.form.completion_time) {
        form.completion_time = moment(this.form.completion_time).format('YYYY-MM-DD HH:mm:ss')
      }
      if (this.form.closure_time) {
        form.closure_time = moment(this.form.closure_time).format('YYYY-MM-DD HH:mm:ss')
      }
      return form;
    },
  },
  created() {
    this.showType = this.$route.query.showType;
    this.id = this.$route.query.id;
    const parentId = this.$route.query.parentId;
    // 如果是添加子项目，则要禁用选择项目和项目类型
    if(parentId) {
      this.form.parent_id = parentId
      this.disableParent = true
      this.form.child_type = 1
    }

    this.getParentProjects(this.id);
    this.judgeHeaderTitle();
    this.getProvinceList();
    if (this.showType !== "0") {
      this.getProjectDetail();
      this.getCityList();
      this.getDistrictList();
    }
  },
};
</script>

<style lang="scss" scoped>
.margin-module {
  margin-top: 30px;
}
.module-box {
  border: #d4d4d4 solid 1px;
  .module-title {
    font-size: 16px;
    font-weight: bold;
    color: #000000;
    padding: 10px;
    background: #fafafa;
    border-bottom: #d4d4d4 solid 1px;
    .module-title-content {
      display: flex;
      justify-content: space-between;
    }
  }
  .module-content {
      padding: 50px;
  }
}

.setMarginBottom {
  margin-bottom: 0px !important;
}

.metro-card-hint {
  color: red;
}

.bottomClass {
  text-align: center;
  padding-bottom: 30px;
}

::v-deep .ant-time-picker {
  width: 100%;
}

::v-deep .ant-time-picker-panel {
  width: 100%;
}

::v-deep .ant-time-picker-panel-narrow .ant-time-picker-panel-input-wrap {
  max-width: none;
  text-align: center;
}

::v-deep .ant-time-picker-panel-input {
  text-align: center;
}

::v-deep .ant-time-picker-panel-input:placeholder-shown {
  text-align: center;
}

::v-deep .ant-time-picker-panel-select:first-child {
  width: 50%;
}

::v-deep .ant-time-picker-panel-select:last-child {
  width: 50%;
}

::v-deep .ant-time-picker-panel-select ul {
  width: 100%;
}

::v-deep .ant-time-picker-panel-select li {
  text-align: center;
}

::v-deep .ant-upload.ant-upload-select-picture-card {
  width: 120px;
  height: 120px;
}

.showPicture {
  width: 120px;
  height: 120px;
}
</style>
